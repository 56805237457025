import React, { useState, useEffect, useRef } from 'react';
import { Select, SelectItem, Input, Card, CardBody, Chip, Button, Checkbox, Link, Tab, Tabs, CardHeader, Image, Divider } from '@nextui-org/react';
import axios from 'axios';
import { ArrowsRightLeftIcon, ArrowTrendingUpIcon, ClockIcon, BanknotesIcon, CreditCardIcon, EnvelopeOpenIcon, CursorArrowRaysIcon, LifebuoyIcon, ArrowPathIcon, StarIcon, UserGroupIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { router, Head } from '@inertiajs/react';
import ReactDOM from 'react-dom';
import GuestLayout from '@/Layouts/GuestLayout';

interface Currency {
    id: number;
    symbol: string;
    name: string;
    image_url: string;
    min_exchange_amount: string;
    max_exchange_amount: string;
    reserve: number;
}

interface FakeExchangeRequest {
    from_currency: Currency;
    to_currency: Currency;
    amount_give: number;
    amount_receive: number;
    wallet_address: string;
    status: string;
}

interface WelcomeProps {
    giveCurrencies: Currency[];
    getCurrencies: Currency[];
    rate?: number;
    error?: string;
    fakeExchanges?: FakeExchangeRequest[]; // Новый пропс
}

interface WelcomeProps {
    giveCurrencies: Currency[];
    getCurrencies: Currency[];
    rate?: number;
    error?: string;
}

const Welcome: React.FC<WelcomeProps> = ({ giveCurrencies, getCurrencies, rate, fakeExchanges, error }) => {
    const [selectedGiveCurrency, setSelectedGiveCurrency] = useState<string>(giveCurrencies[0]?.id.toString() || '');
    const [selectedGetCurrency, setSelectedGetCurrency] = useState<string>(() => {
        // Устанавливаем первую доступную валюту, которая не совпадает с "отдаю"
        const defaultGetCurrency = getCurrencies.find(currency => currency.id.toString() !== giveCurrencies[0]?.id.toString());
        return defaultGetCurrency ? defaultGetCurrency.id.toString() : '';
    });
    const buttonGiveRef = useRef<HTMLButtonElement | null>(null);
    const buttonGetRef = useRef<HTMLButtonElement | null>(null);

    // Позиции выпадающих списков
    const [giveDropdownPosition, setGiveDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
    const [getDropdownPosition, setGetDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

    // Видимость выпадающих списков
    const [isGiveDropdownOpen, setIsGiveDropdownOpen] = useState(false);
    const [isGetDropdownOpen, setIsGetDropdownOpen] = useState(false);

    const calculateDropdownPosition = (
        buttonRef: React.RefObject<HTMLButtonElement>,
        setDropdownPosition: React.Dispatch<React.SetStateAction<{ top: number; left: number; width: number; }>>
    ) => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
            });
        }
    };

    const toggleGiveDropdown = () => {
        setIsGiveDropdownOpen(!isGiveDropdownOpen);
        if (!isGiveDropdownOpen) {
            calculateDropdownPosition(buttonGiveRef, setGiveDropdownPosition);
        }
    };

    const toggleGetDropdown = () => {
        setIsGetDropdownOpen(!isGetDropdownOpen);
        if (!isGetDropdownOpen) {
            calculateDropdownPosition(buttonGetRef, setGetDropdownPosition);
        }
    };



    useEffect(() => {
        if (isGiveDropdownOpen || isGetDropdownOpen) {
            document.body.style.overflow = 'hidden'; // Блокировка прокрутки страницы
        } else {
            document.body.style.overflow = 'auto'; // Разблокировка прокрутки
        }

        // Очистка эффекта при размонтировании компонента
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isGiveDropdownOpen, isGetDropdownOpen]);


    const selectedGiveCurrencyObj = giveCurrencies.find((currency) => currency.id.toString() === selectedGiveCurrency);
    const selectedGetCurrencyObj = getCurrencies.find((currency) => currency.id.toString() === selectedGetCurrency);

    const [amount, setAmount] = useState<string>('3030');
    const [receivedAmount, setReceivedAmount] = useState<string>('');
    const [exchangeRate, setExchangeRate] = useState<number | null>(rate || null);
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [timer, setTimer] = useState<number>(15);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

    // Состояния для чекбоксов
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [isAMLAccepted, setIsAMLAccepted] = useState<boolean>(false);
    const [isNotUSCitizen, setIsNotUSCitizen] = useState<boolean>(false);

    // Состояния для ошибок
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isFormValid, setIsFormValid] = useState<boolean>(false); // Состояние для отслеживания валидности формы
    const [isLoading, setIsLoading] = useState<boolean>(false); // Новое состояние для загрузки

    // Функция для получения курса с помощью axios
    const fetchExchangeRate = async (giveCurrencyId: string, getCurrencyId: string) => {
        try {
            const response = await axios.post('/get-exchange-rate', {
                give_currency_id: giveCurrencyId,
                get_currency_id: getCurrencyId,
            });

            if (response.data.rate) {
                setExchangeRate(response.data.rate);
            }
        } catch (error) {
            console.error('Ошибка при получении курса', error);
        }
    };

    // Рассчитываем получаемую сумму при изменении курса или суммы
    useEffect(() => {
        if (exchangeRate && amount) {
            const result = (parseFloat(amount) * exchangeRate).toFixed(2);
            setReceivedAmount(result);
        } else {
            setReceivedAmount('');
        }
    }, [amount, exchangeRate]);

    // Обновляем курс при изменении валют
    useEffect(() => {
        fetchExchangeRate(selectedGiveCurrency, selectedGetCurrency);
    }, [selectedGiveCurrency, selectedGetCurrency]);

    // Таймер для обновления курса каждые 15 секунд
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer((prev) => {
                if (prev === 1) {
                    fetchExchangeRate(selectedGiveCurrency, selectedGetCurrency);
                    return 15;
                } else {
                    return prev - 1;
                }
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedGiveCurrency, selectedGetCurrency]);

    // Функция для обмена валют
    const swapCurrencies = () => {
        const previousGiveCurrency = selectedGiveCurrency;
        setSelectedGiveCurrency(selectedGetCurrency);
        setSelectedGetCurrency(previousGiveCurrency);
    };

    // Валидация полей
    const validateFields = () => {
        const newErrors: { [key: string]: string } = {};

        // Проверка суммы
        if (!amount || isNaN(Number(amount)) || parseFloat(amount) <= 0) {
            newErrors.amount = 'Введите корректную сумму';
        } else {
            const minAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.min_exchange_amount || '0');
            const maxAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.max_exchange_amount || '0');

            if (parseFloat(amount) < minAmount || parseFloat(amount) > maxAmount) {
                newErrors.amount = `Сумма должна быть в пределах от ${minAmount} до ${maxAmount}`;
            }
        }

        // Проверка кошелька
        if (!walletAddress) {
            newErrors.walletAddress = 'Введите адрес кошелька';
        }

        // Проверка email
        if (!email) {
            newErrors.email = 'Введите email';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newErrors.email = 'Введите корректный email';
        }

        // Проверка чекбоксов
        if (!isTermsAccepted || !isAMLAccepted || !isNotUSCitizen) {
            newErrors.terms = 'Пожалуйста, примите все условия';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Валидация отдельных полей в реальном времени
    const validateAmount = (value: string) => {
        let error = '';
        const minAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.min_exchange_amount || '0');
        const maxAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.max_exchange_amount || '0');

        if (!value || isNaN(Number(value)) || parseFloat(value) <= 0) {
            error = 'Введите корректную сумму';
        } else if (parseFloat(value) < minAmount || parseFloat(value) > maxAmount) {
            error = `Сумма должна быть в пределах от ${minAmount} до ${maxAmount}`;
        }
        setErrors(prev => ({ ...prev, amount: error }));
        checkFormValidity();
    };

    const validateWalletAddress = (value: string) => {
        let error = value ? '' : 'Введите адрес кошелька';
        setErrors(prev => ({ ...prev, walletAddress: error }));
        checkFormValidity();
    };

    const validateEmail = (value: string) => {
        let error = '';

        if (!value) {
            error = 'Введите email';
        } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
            error = 'Введите корректный email';
        }

        setErrors(prev => ({ ...prev, email: error }));
        checkFormValidity();
    };

    // Проверка валидности формы
    const checkFormValidity = () => {
        const isValid = !errors.amount && !errors.walletAddress && !errors.email &&
            !!amount && !!walletAddress && !!email &&
            isTermsAccepted && isAMLAccepted && isNotUSCitizen;

        console.log('Checking form validity:', {
            amountError: errors.amount,
            walletAddressError: errors.walletAddress,
            emailError: errors.email,
            amount: !!amount,
            walletAddress: !!walletAddress,
            email: !!email,
            isTermsAccepted,
            isAMLAccepted,
            isNotUSCitizen,
            isValid
        });

        setIsFormValid(isValid);
    };

    // Обработка отправки данных
    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
        }

        setIsLoading(true); // Блокируем кнопку после нажатия
        try {
            const response = await axios.post('/exchange/create', {
                give_currency_id: selectedGiveCurrency,
                get_currency_id: selectedGetCurrency,
                amount_give: amount,
                amount_receive: receivedAmount,
                wallet_address: walletAddress,
                email: email,
            });

            if (response.data.success) {
                router.visit(`/exchange/${response.data.request_id}`);
            }
        } catch (error) {
            console.error('Ошибка при создании заявки', error);
        } finally {
            setIsLoading(false); // Разблокируем кнопку после завершения
        }
    };

    useEffect(() => {
        checkFormValidity();
    }, [isTermsAccepted, isAMLAccepted, isNotUSCitizen, amount, walletAddress, email, errors]);



    return (
        <>
            <div className="container mx-auto px-2 max-w-screen-xl">
                <Head>
                    <title>Главная</title>
                    <meta name="description" content="Enzer Exchange" />
                </Head>

                {/* Второе уведомление с градиентом */}
                <div className="my-8 bg-gradient-to-r from-blue-900 via-zinc-800 to-zinc-900 text-white rounded-2xl p-4 flex justify-between items-center">
                    <div className="flex items-center">
                        <p className="text-lg font-bold">
                            <span className="text-pink-200">Новые AML алгоритмы</span> - ваша безопасность в переводах
                        </p>
                    </div>
                    <div className="flex justify-end items-center space-x-4">
                        <Link isExternal showAnchorIcon href='https://enzer-exchange.gitbook.io/enzer-exchange-doc' className="text-white bg-gradient-to-r from-blue-500 to-zinc-800 rounded-2xl px-4 py-2 hover:opacity-90 transition">
                            Узнать больше
                        </Link>
                    </div>
                    <button className="ml-4">
                        <XMarkIcon className="h-5 w-5 text-white" />
                    </button>
                </div>
                <div className='flex justify-between items-center flex-col lg:flex-row'>
                    <h1 className="text-2xl font-bold text-center">Обмен <span className='uppercase'>{giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.name || ''}</span> ({giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol || ''}) на <span className='uppercase'>{getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.name || ''}</span> ({getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol || ''})</h1>
                    {exchangeRate !== null && (
                        <Card className="text-center rounded-3xl w-full lg:max-w-lg my-3 border border-blue-800 shadow-xl">
                            <CardBody className="flex justify-start">
                                <div className="flex items-center">
                                    <Chip variant="flat" color="primary">
                                        1 {giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol || ''} / {exchangeRate} {getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol || ''}
                                    </Chip>
                                    <div className="flex justify-between items-center text-xs ml-2">
                                        <Chip startContent={<ClockIcon className='size-5 mr-1' />} variant="flat" color="default">
                                            {timer} секунд
                                        </Chip>
                                    </div>
                                </div>
                                <p className="text-xs mt-2">Курс будет зафиксирован после создания заявки</p>
                            </CardBody>
                        </Card>
                    )}
                </div>

                {error && (
                    <div className="bg-red-500 text-white text-center p-2 rounded">
                        {error}
                    </div>
                )}

                <div className='flex justify-between items-center flex-col lg:flex-row'>
                    <Card className='border rounded-3xl mb-0 border-zinc-800 w-full lg:max-w-lg shadow-xl'>
                        <CardBody>
                            <div className="flex justify-center items-start p-4 space-x-2">
                                <div className="w-full">
                                    <label htmlFor="giveCurrency" className="block text-md text-white mb-2">
                                        Отдаю
                                    </label>
                                    <button
                                        ref={buttonGiveRef}
                                        id="giveCurrency"
                                        onClick={toggleGiveDropdown}
                                        className="relative w-full bg-zinc-800 rounded-xl shadow-sm pl-3 pr-10 py-3 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm flex items-center h-[48px]" // высота кнопки должна совпадать с высотой Input
                                    >
                                        <img
                                            src={selectedGiveCurrencyObj?.image_url}
                                            alt={selectedGiveCurrencyObj?.symbol}
                                            className="w-6 h-6 mr-2"
                                        />
                                        <span className="truncate">{selectedGiveCurrencyObj?.symbol}</span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M5.5 7L10 11.5 14.5 7H5.5z" />
                                            </svg>
                                        </span>
                                    </button>

                                    {isGiveDropdownOpen && ReactDOM.createPortal(
                                        <>
                                            <div
                                                className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
                                                onClick={() => setIsGiveDropdownOpen(false)}
                                            />
                                            <div
                                                className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-zinc-800 shadow-lg max-h-56 rounded-3xl py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto sm:text-sm z-50"
                                                style={{
                                                    width: '300px', // Ширина окна выпадающего меню
                                                }}
                                            >
                                                {giveCurrencies.map((currency) => (
                                                    <>
                                                        <button
                                                            key={currency.id}
                                                            className="w-full flex items-center px-4 py-3 bg-zinc-800 hover:bg-zinc-700 text-left"
                                                            onClick={() => {
                                                                setSelectedGiveCurrency(currency.id.toString());
                                                                setIsGiveDropdownOpen(false);
                                                            }}
                                                        >
                                                            <img
                                                                src={currency.image_url}
                                                                alt={currency.symbol}
                                                                className="w-8 h-8 mr-3 object-contain" // Изменено на w-8 h-8 и добавлен object-contain для одинакового размера изображения и корректного масштабирования
                                                            />
                                                            <div className="flex flex-col">
                                                                <span className="text-base font-semibold text-white">{currency.symbol}</span> {/* Увеличена высота текста и установлена одинаковая высота */}
                                                                <span className="text-sm text-gray-400">{currency.name}</span>
                                                            </div>

                                                        </button>
                                                        <Divider />
                                                    </>
                                                ))}
                                            </div>
                                        </>,
                                        document.body
                                    )}
                                </div>

                                <div className="w-full p-1">
                                    <Input
                                        label="Сумма"
                                        labelPlacement='outside'
                                        type="number"
                                        size='lg'
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value);
                                            validateAmount(e.target.value);
                                        }}
                                        placeholder={`Мин ${giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.min_exchange_amount || ''}`}
                                        description={`Макс ${giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.max_exchange_amount || ''}`}
                                        isInvalid={!!errors.amount}
                                        className="h-[48px]"
                                    />
                                </div>
                            </div>
                            <div className='flex justify-center items-center'>{errors.amount && <p className="text-red-500">{errors.amount}</p>}</div>
                        </CardBody>
                    </Card>

                    <Button isIconOnly className='my-2' color='primary' variant='shadow' onPress={swapCurrencies}>
                        <ArrowsRightLeftIcon className="size-6" />
                    </Button>

                    <Card className='border rounded-3xl border-zinc-800 w-full lg:max-w-lg shadow-xl'>
                        <CardBody>
                            <div className='flex justify-center items-start p-4 space-x-2'>
                                <div className="w-full ml-1">
                                    <div className="w-full ml-1">
                                        <label htmlFor="getCurrency" className="block text-md text-white mb-2">
                                            Получаю
                                        </label>
                                        <button
                                            ref={buttonGetRef}
                                            id="getCurrency"
                                            onClick={toggleGetDropdown}
                                            className="relative w-full bg-zinc-800 rounded-xl shadow-sm pl-3 pr-10 py-3 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm flex items-center h-[48px]"
                                        >
                                            <img
                                                src={selectedGetCurrencyObj?.image_url}
                                                alt={selectedGetCurrencyObj?.symbol}
                                                className="w-6 h-6 mr-2"
                                            />
                                            <span className="truncate">{selectedGetCurrencyObj?.symbol}</span>
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M5.5 7L10 11.5 14.5 7H5.5z" />
                                                </svg>
                                            </span>
                                        </button>

                                        {isGetDropdownOpen && ReactDOM.createPortal(
                                            <>
                                                <div
                                                    className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
                                                    onClick={() => setIsGetDropdownOpen(false)}
                                                />
                                                <div
                                                    className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-zinc-800 shadow-lg max-h-56 rounded-3xl py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto sm:text-sm z-50"
                                                    style={{
                                                        width: '300px', // Ширина окна выпадающего меню
                                                    }}
                                                >
                                                    {getCurrencies.map((currency) => (
                                                        <>
                                                            <button
                                                                key={currency.id}
                                                                className="w-full flex items-center px-4 py-3 bg-zinc-800 hover:bg-zinc-700 text-left"
                                                                onClick={() => {
                                                                    setSelectedGetCurrency(currency.id.toString());
                                                                    setIsGetDropdownOpen(false);
                                                                }}
                                                            >
                                                                <img src={currency.image_url} alt={currency.symbol} className="w-6 h-6 mr-2" />
                                                                <div className="flex flex-col">
                                                                    <span className="text-sm font-medium">{currency.symbol}</span>
                                                                    <span className="text-xs text-gray-400">{currency.name}</span>
                                                                </div>
                                                            </button>
                                                            <Divider />
                                                        </>
                                                    ))}
                                                </div>
                                            </>,
                                            document.body
                                        )}
                                    </div>


                                </div>
                                <div className="w-full  p-1">
                                    <Input
                                        label="Сумма"
                                        labelPlacement='outside'
                                        type="text"
                                        value={receivedAmount}
                                        readOnly
                                        startContent={
                                            <div className="pointer-events-none flex items-center">
                                                <span className="text-default-400 text-small"><BanknotesIcon className='size-6' /></span>
                                            </div>
                                        }
                                        endContent={`${getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol || ''}`}
                                        description={`Резерв ${getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.reserve || ''}`}
                                        size='lg'
                                        className="h-[48px]"
                                    />
                                </div>
                            </div>
                            <div className="mt-2 p-4">
                                <Input
                                    label="Реквизиты получателя"
                                    type="text"
                                    value={walletAddress}
                                    onChange={(e) => {
                                        setWalletAddress(e.target.value);
                                        validateWalletAddress(e.target.value);
                                    }}
                                    labelPlacement="outside"
                                    startContent={<CreditCardIcon className='size-6' />}
                                    description={`Укажите ваш счет ${getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.name || ''}`}
                                    size='lg'
                                    isInvalid={!!errors.walletAddress}
                                />
                                {errors.walletAddress && <p className="text-red-500">{errors.walletAddress}</p>}
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className='mt-4 lg:mt-20  flex justify-center items-center flex-col'>
                    <Card className='border rounded-3xl border-zinc-800 w-full lg:max-w-lg shadow-xl'>
                        <CardBody>
                            <div className='flex justify-center items-center'>
                                <div className="w-full ml-1 p-4">
                                    <Input
                                        label="Ваш почтовый аккаунт"
                                        type="text"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            validateEmail(e.target.value);
                                        }}
                                        labelPlacement="outside"
                                        placeholder='example@example.com'
                                        startContent={<EnvelopeOpenIcon className='size-6' />}
                                        description="Укажите ваш Email"
                                        size='lg'
                                        isInvalid={!!errors.email}
                                    />
                                    {errors.email && <p className="text-red-500">{errors.email}</p>}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className='my-5 flex flex-col justify-start'>
                        <Checkbox
                            isRequired
                            isSelected={isTermsAccepted}
                            onChange={(e) => setIsTermsAccepted(e.target.checked)}
                        >
                            Соглашаюсь с <Link href='https://enzer-exchange.gitbook.io/enzer-exchange-doc' color='primary' underline="always" showAnchorIcon isExternal>Правилами предоставления услуг</Link>
                        </Checkbox>

                        <Checkbox
                            isRequired
                            isSelected={isAMLAccepted}
                            onChange={(e) => setIsAMLAccepted(e.target.checked)}
                        >
                            Соглашаюсь с <Link href='https://enzer-exchange.gitbook.io/enzer-exchange-doc' color='primary' underline="always" showAnchorIcon isExternal>AML/CTF & KYC</Link>
                        </Checkbox>

                        <Checkbox
                            isRequired
                            isSelected={isNotUSCitizen}
                            onChange={(e) => setIsNotUSCitizen(e.target.checked)}
                        >
                            Я не являюсь гражданином США и Израиля
                        </Checkbox>
                    </div>
                </div>

                <div className='flex justify-center items-center'>
                    <Button
                        fullWidth
                        className='max-w-lg border-2 border-blue-400 rounded-3xl'
                        size='lg'
                        color="primary"
                        variant="shadow"
                        onPress={handleSubmit}
                        isDisabled={!isFormValid || isLoading} // Отключаем кнопку при невалидной форме или когда идет загрузка
                    >
                        {isLoading ? (
                            <Chip color='primary' startContent={<ArrowPathIcon className="size-5 mr-1 animate-spin" />}>Обработка...</Chip>
                        ) : (
                            'Приступить к обмену'
                        )}
                    </Button>
                </div>

                <div className="container mx-auto px-4 py-16">
                    <h2 className="text-3xl font-bold text-center mb-8">Лидер рынка обмена <br /> цифровых валют</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
                        <div className="flex flex-col items-center">
                            <ArrowPathIcon className="h-auto w-16 text-purple-500 mb-4 bg-zinc-900 rounded-2xl p-2 shadow-xl" />
                            <h3 className="text-2xl font-bold">755 901</h3>
                            <p className="text-lg text-gray-400">Обменов</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <UserGroupIcon className="h-auto w-16 text-purple-500 mb-4 bg-zinc-900 rounded-2xl p-2 shadow-xl" />
                            <h3 className="text-2xl font-bold">300 000+</h3>
                            <p className="text-lg text-gray-400">Клиентов</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <StarIcon className="h-auto w-16 text-purple-500 mb-4 bg-zinc-900 rounded-2xl p-2 shadow-xl" />
                            <h3 className="text-2xl font-bold">7 752</h3>
                            <p className="text-lg text-gray-400">Отзывов</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <ArrowPathIcon className="h-auto w-16 text-purple-500 mb-4 bg-zinc-900 rounded-2xl p-2 shadow-xl" />
                            <h3 className="text-2xl font-bold">4 000+</h3>
                            <p className="text-lg text-gray-400">Направлений обмена</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <ClockIcon className="h-auto w-16 text-purple-500 mb-4 bg-zinc-900 rounded-2xl p-2 shadow-xl" />
                            <h3 className="text-2xl font-bold">07:21</h3>
                            <p className="text-lg text-gray-400">Среднее время заявки</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <LifebuoyIcon className="h-auto w-16 text-purple-500 mb-4 bg-zinc-900 rounded-2xl p-2 shadow-xl" />
                            <h3 className="text-2xl font-bold">24 / 7</h3>
                            <p className="text-lg text-gray-400">Служба поддержки</p>
                        </div>
                    </div>
                </div>

                <div className="container lg:px-32 mt-10">
                    <Tabs aria-label="Options">
                        <Tab key="photos" title="Последние обмены">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-4 rounded-3xl">
                                {fakeExchanges && fakeExchanges.length > 0 ? (
                                    fakeExchanges.map((exchange, index) => (
                                        <Card key={index} className="border border-gray-800 rounded-3xl shadow-xl">
                                            <CardBody>
                                                <div className="flex flex-col lg:flex-row justify-between items-center px-2 py-2">

                                                    {/* Отдаю */}
                                                    <div className="flex items-center justify-center lg:w-1/3 mb-4 lg:mb-0">
                                                        <img
                                                            src={exchange.from_currency.image_url}
                                                            alt={exchange.from_currency.name}
                                                            className="w-8 h-8 mr-4"
                                                        />
                                                        <div className="text-center lg:text-left">
                                                            <h3 className="font-semibold text-md">
                                                                {exchange.from_currency.symbol}
                                                            </h3>
                                                            <p className="lg:block text-xs">{Number(exchange.amount_give).toFixed(3)}</p>
                                                        </div>
                                                    </div>

                                                    {/* Стрелка обмена */}
                                                    <div className="hidden lg:block my-4 lg:my-0 lg:w-1/5">
                                                        <ArrowsRightLeftIcon className="h-6 w-6" />
                                                    </div>

                                                    {/* Получаю */}
                                                    <div className="flex items-center justify-center lg:w-1/3">
                                                        <img
                                                            src={exchange.to_currency.image_url}
                                                            alt={exchange.to_currency.name}
                                                            className="w-8 h-8 mr-4"
                                                        />
                                                        <div className="text-center lg:text-left">
                                                            <h3 className="font-semibold text-md">
                                                                {exchange.to_currency.symbol}
                                                            </h3>
                                                            <p className="lg:block text-xs">{Number(exchange.amount_receive).toFixed(2)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ))
                                ) : (
                                    <p>Нет фейковых обменов</p>
                                )}
                            </div>
                        </Tab>
                    </Tabs>

                    <Card className='border-2 border-blue-500 rounded-3xl mt-16'>
                        <CardBody className='flex'>
                            <p className='mb-2 flex items-center'><InformationCircleIcon className='w-10 h-auto p-2 bg-zinc-800 rounded-2xl mr-2' /> Надежен ли обмен {giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.name || ''} ({giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol || ''}) на {getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.name || ''} ({getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol || ''})? </p>
                            <p className='text-xs'>Enzer Exchange предлагает анонимный и безопасный обмен {giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.name || ''} ({giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol || ''}) на {getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.name || ''} ({getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol || ''}). Гарантом безопасности является круглосуточная поддержка, а также в сервисе реализована Система проверки AML, с ее помощью вы обезопасите себя и свой бизнес от незаконных средств.
                                <br /><br />
                                Помимо обмена {giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.name || ''} ({giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol || ''}) на {getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.name || ''} ({getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol || ''}) в Enzer Exchange доступен большой выбор других направлений, который постоянно обновляется.</p>
                        </CardBody>
                    </Card>
                </div>
                <div className="container mx-auto px-4 lg:px-32 py-16">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                        {/* Первая карточка занимает две строки на больших экранах */}
                        <Card className="text-white rounded-3xl shadow-lg lg:row-span-2 border-2 border-zinc-800 flex flex-col justify-between min-h-[300px]">
                            <CardHeader className="z-10 top-1 flex-col !items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Круглосуточная поддержка</p>
                                <h4 className="text-white font-medium text-md">Наши специалисты готовы помочь вам на любом этапе обмена и ответить на ваши вопросы</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src="/assets/img/obj.png"
                            />
                        </Card>

                        {/* Остальные карточки */}
                        <Card className="text-white rounded-3xl shadow-lg border-2 border-zinc-800 flex flex-col justify-between min-h-[200px]">
                            <CardHeader className="z-10 top-1 flex-col !items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Система проверки AML</p>
                                <h4 className="text-white font-medium text-md">Обезопасьте себя и свой бизнес от незаконных средств</h4>
                            </CardHeader>
                        </Card>

                        <Card className="text-white rounded-3xl shadow-lg border-2 border-zinc-800 flex flex-col justify-between min-h-[200px]">
                            <CardHeader className="z-10 top-1 flex-col !items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Выгодный курс</p>
                                <h4 className="text-white font-medium text-md">Мы постоянно следим за актуальными курсами и предлагаем вам самые выгодные</h4>
                            </CardHeader>
                        </Card>

                        <Card className="text-white rounded-3xl shadow-lg border-2 border-zinc-800 flex flex-col justify-between min-h-[200px]">
                            <CardHeader className="z-10 top-1 flex-col !items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Большой выбор направлений обмена</p>
                                <h4 className="text-white font-medium text-md">Покупаем и продаем все основные валюты и постоянно добавляем новые направления обмена</h4>
                            </CardHeader>
                        </Card>

                        <Card className="text-white rounded-3xl shadow-lg border-2 border-zinc-800 flex flex-col justify-between min-h-[200px]">
                            <CardHeader className="z-10 top-1 flex-col !items-start">
                                <p className="text-tiny text-white/60 uppercase font-bold">Анонимный и безопасный</p>
                                <h4 className="text-white font-medium text-md">Не требуем прохождения верификации (KYC), поэтому любой человек может совершить обмен без предоставления личных данных</h4>
                            </CardHeader>
                        </Card>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Welcome;
